import { useContext, useEffect, useState } from "react"
import { AppContext } from "../../context/AppContext/AppContextProvider"
import axios from "axios"
import { _3dService } from "../../services/_3DService"
import { RenderTypes } from "../../constants/RenderTypes"


let defaultAsset = 'https://target-demo-assets.s3.amazonaws.com/assets_3d/840a76c0-f752-450f-bc03-4fce9f2612ad.glb'
export const Viewer3D = ({show = false}) => {

    const [assetURL, setAssetURL] = useState(defaultAsset)
    const {file, productId, renderType, _3dAssetURL} = useContext(AppContext)

    const resetView = () => {
        _3dService.reset3DView()
    }

    useEffect(() => {
        // if(_3dAssetURL === null && renderType === RenderTypes["3D"]) {
        //     alert("Sorry! The limit to generate 3d model has exceeded. Please contact sales@mirrar.com.")
        //     return
        // }
        if(!_3dAssetURL) return
        
        async function init3d() {
            await _3dService.initialize3D(_3dAssetURL)
        }

        if(renderType === RenderTypes["3D"]) {
            init3d()
        }
    }, [_3dAssetURL, renderType])

    useEffect(() => {
        if(file && productId && renderType === RenderTypes["3D"]) {
            
        }
    }, [file, productId, renderType])

    return (
        <div 
            style={{
                display: show ? 'flex': 'none'
            }} 
            className="w-full h-full relative"
        >
            <canvas id="canvas" className="w-full h-full"></canvas>
            <div className="absolute z-100 flex top-0 right-0 w-full justify-end">
                <button 
                    className="px-4 py-2 rounded m-2 bg-white border-2 border-black hover:bg-white/50"
                    onClick={resetView}
                >Reset</button>
            </div>
        </div>
    )
}