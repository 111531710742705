import { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { _3dService } from '../../services/_3DService'

export function ExportAssetsPopup({ open, onClose = () => {} }) {

  return (
    <Transition
      show={open}
      as={Fragment}
    >
      <Dialog onClose={onClose}>
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      <div className='fixed inset-0 w-[80%] h-full mx-auto flex items-center justify-center'>
        <Dialog.Panel>
        {/* <div className='flex flex-col justify-center items-center h-fit px-[20px] py-[40px] bg-white rounded'>
            <button 
                className='border-2 border-black px-5 py-2 cursor-pointer w-full flex justify-center items-center'
                onClick={() => {
                    _3dService.exportGLB()
                }}
            >
                Export 3D Asset
            </button>
            <button 
                className='mx-2 border-2 border-black px-5 py-2 mt-5 cursor-pointer w-full flex justify-center items-center'
                onClick={() => {
                    _3dService.start360Capture(() => {
                        alert("Video was successfully generated")
                    })
                }}
            >
                Export 360 Video Asset
            </button>

            <button 
                className='border-2 border-black px-5 py-2 mt-5 cursor-pointer w-full flex justify-center items-center'
                onClick={() => {
                    _3dService.start360PhotosCapture(() => {
                        alert("Angle Photos were successfully generated. Please check the folder that you selected earlier.")
                    })
                }}
            >
                Export Images At Different Angles
            </button>
        </div> */}

        <div className='bg-white px-[20px] py-[40px] rounded-lg text-center'>
            <div>Kindly use a desktop device if you want to export the assets in required format.</div>
            <div className=''>
                <button
                    className='mt-[20px] outline-none border-2 border-black px-5 py-2 cursor-pointer w-full flex justify-center items-center'
                    onClick={onClose}
                >
                    Continue
                </button>
            </div>
        </div>
        </Dialog.Panel>
      </div>
      </Dialog>
    </Transition>
  )
}