import { createContext, useEffect, useState } from 'react';
import { AvailableColors } from '../../constants/ColorList';
import { RenderTypes } from '../../constants/RenderTypes';
import axios from 'axios';
import { hexToRgb } from '../../utils/color';
import { _3dService } from '../../services/_3DService';

const AppContext = createContext();

const AppContextProvider = ({children}) => {

    const [currentColor, setCurrentColor] = useState(AvailableColors[0])
    const [file, setFile] = useState(null)
    const [initialFile, setInitialFile] = useState(null)
    const [renderType, setRenderType] = useState(RenderTypes['3D'])
    const [_2dAssetURL, set2dAssetURL] = useState()
    const [_3dAssetURL, set3dAssetURL] = useState()
    const [productId, setProductId] = useState()
    const [loading, setLoading] = useState(false)
    const [_2dAssetURLList, set2dAssetURLList] = useState([])

    useEffect(() => {
        _3dService.setLoadingFunction(setLoading)
    }, [])

    const onFileUpload = (evt) => {
        if (evt.target.files) {
            let file = evt.target.files[0]
            setFile(file);
            setInitialFile(file);
            const formData = new FormData()
            formData.append('files', file);
            try {
                axios.post('https://api-target.mirrar.io/upload-products', formData)
                    .then(response => {
                        let data = response.data?.response?.data
                        console.log(data)
                        setProductId(data[0].product_id)
                    }).catch(err => {
                        console.error(err)
                    })
            } catch (error) {
                console.error(error)
            }

        }
    }

    const changeFileColor = (color, asset) => {
        if(file) {
            console.log(file)
            // setLoading(true)
            let asset_2d = _2dAssetURLList.filter(element => asset.dominant_color === element.dominant_color)[0]
            set2dAssetURL(asset_2d.s3_uri + '?' + Date.now())
            // setLoading(false)
        }
    }

    const getColorListImages = (colorList) => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            set2dAssetURLList([])
            axios.post('https://api-target.mirrar.io/get-processed-products', {
                "data": [productId],
                "colors": colorList.map(color => hexToRgb(color))
            }).then(response => {
                let apiData = response.data
                console.log(apiData?.response?.data[0]?.variants)
                set2dAssetURLList(apiData?.response?.data[0]?.variants)
                set2dAssetURL(apiData?.response?.data[0]?.variants[0]?.s3_uri + '?' + Date.now())
                setLoading(false)
                resolve()
            }).catch(err => {
                console.log(err)
                setLoading(false)
                reject()
            })
        })
    }

    const get3DAsset = () => {
        return new Promise((resolve, reject) => {
            axios.get(`https://api-target.mirrar.io/get-3d-asset?product_id=${productId}`)
                .then(response => {
                    console.log(response.data)
                    if(response.data.asset_uri) {
                        set3dAssetURL(response.data.asset_uri)
                    } else {
                        set3dAssetURL(null)
                        alert("Sorry! The limit to generate 3d model has exceeded. Please contact sales@mirrar.com.")
                    }
                    resolve()
                }).catch(err => {
                    console.log(err)
                    reject()
                })
        })
    }

    const getAssets = async () => {
        try {
            setLoading(true)
            // await getColorListImages(AvailableColors)
            await get3DAsset()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
        
    }

    const download2DImage = () => {
        const imageUrl = _2dAssetURL;
        const imageName = imageUrl.split('/').pop();; // Extract or define image name
    
        // Create an <a> element for the download
        const downloadLink = document.createElement('a');
        downloadLink.href = imageUrl;
        downloadLink.download = imageName; // This suggests a filename for the download
    
        // Append to the document and trigger the download
        document.body.appendChild(downloadLink);
        downloadLink.click();
    
        // Clean up by removing the <a> element after download
        document.body.removeChild(downloadLink);
    }

    useEffect(() => {
        if(productId) {
            getAssets()
        }
    }, [productId])

    return <AppContext.Provider value={{
        currentColor, setCurrentColor,
        file, setFile,
        renderType, setRenderType,
        changeFileColor, getColorListImages,
        initialFile, setInitialFile,
        _2dAssetURL, set2dAssetURL,
        _3dAssetURL, set3dAssetURL,
        productId, setProductId,
        loading, setLoading,
        _2dAssetURLList, set2dAssetURLList,
        onFileUpload, download2DImage
    }}>
        {children}
    </AppContext.Provider>
}

export { AppContext, AppContextProvider  };