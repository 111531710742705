import { useContext, useEffect, useState } from "react"
import { RenderTypes } from "../../constants/RenderTypes"
import { Viewer2D } from "./Viewer2D"
import { Viewer3D } from "./Viewer3D"
import { AppContext } from "../../context/AppContext/AppContextProvider"

export const Viewer = ({}) => {
    const {file, renderType} = useContext(AppContext)
    const [assetURL, setAssetURL] = useState()
    
    useEffect(() => {
        if(file) {
            const fileReader = new FileReader();
            fileReader.onload = event => {
                setAssetURL(event.target.result);
            }
            fileReader.readAsDataURL(file);
        }
    }, [file])
    return (
        <div className="w-full h-full">
                {/* <Viewer2D show={renderType === RenderTypes["2D"]} assetURL={assetURL}></Viewer2D> */}
                <Viewer3D show={renderType === RenderTypes["3D"]} assetURL={assetURL}></Viewer3D>
        </div>
    )
}