import AnimatedLoadingSvg from "../Animated/AnimatedLoadingSVG"

export const Loader = ({show = false}) => {
    return (
        <>
            {
                show && <div className="absolute top-0 left-0 w-full h-full bg-black/50 flex flex-col justify-center items-center">
                    <div className="w-[100px] h-[100px]">
                        <AnimatedLoadingSvg></AnimatedLoadingSvg>
                    </div>
                    <div className="text-white font-bold text-lg">
                        Preparing Assets
                    </div>
                </div>
            }
        </>
    )
}