import { useContext } from "react";
import { AppContext } from "../../context/AppContext/AppContextProvider";
import axios from 'axios'
export const FileUpload = ({ }) => {

    const { setFile, setInitialFile, setProductId, setLoading, onFileUpload } = useContext(AppContext)


    return (
        <div className="flex items-center justify-center min-w-[178px] border border-dashed border-gray cursor-pointer">
            <label htmlFor="dropzone-file" className="flex w-full h-full justify-center items-center">
                <div className="mx-[10px] text-gray-500 text-center w-full h-full flex flex-col items-center justify-center cursor-pointer"><span className="text-2xl">+</span> Upload</div> 
                {/* <div>
                    <img className="w-[20px]" src="/assets/Vector.png" alt="" />
                </div> */}
                <input id="dropzone-file" type="file" className="hidden" onChange={onFileUpload} />
            </label>
        </div>
    )
}