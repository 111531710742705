import { useContext, useEffect, useState } from "react"
import { ImagesList } from "../../constants/ImagesList"
import { AppContext } from "../../context/AppContext/AppContextProvider"

export const ImageSlider = ({}) => {
    const [imagesList, setImagesList] = useState(ImagesList)
    const {file, setFile, onFileUpload} = useContext(AppContext)
    const [selectedIndex, setSelectedIndex] = useState(0)

    useEffect(() => {
        if(!file && imagesList) {
            onImageClick(imagesList[0].assetURL)
        }
    }, [imagesList, file])
    
    const onImageClick = (assetURL, index) => {
        fetch(assetURL)
        .then(res => res.blob())
        .then(blob => {
            const fileSplits = assetURL.split('/')
            const fileName = fileSplits[fileSplits.length - 1]
            const file = new File([blob], fileName);
            onFileUpload({target: {files: [file]}})
            setSelectedIndex(index)
        })
        .catch(error => console.error('Error converting image to Blob:', error));
    }

    return (
        <div className='flex'>
            {
                imagesList.map((image, index) => {
                    let selected = selectedIndex === index
                    return (
                        <div 
                            className={`md:w-[178px] md:h-[131px] w-[130px] h-[100px] mx-[10px] border ${selected? 'border-black': 'border-gray'}`}
                            onClick={() => {
                                onImageClick(image.assetURL, index)
                            }}
                        >
                            <img 
                                className='w-full h-full object-contain'
                                src={image.assetURL}
                                alt={''}
                            />
                        </div>
                    )
                })
            }
            
        </div>
    )
}