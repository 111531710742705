import { Disclosure, Transition } from '@headlessui/react'

export const Section = ({
    title = '',
    children,
    open = false,
    onClick = () => {}
}) => {
    return (
        <div>
            <div className={"w-full cursor-pointer border-b-[3px] border-black"}>
                <div className="align flex justify-between w-full" id="clickableArea" onClick={onClick}>
                    <h2>{title}</h2>
                        <img src={
                            open ? "/assets/Vector 3.png" : "/assets/Group 5.png"
                        } alt="" id="vectorImage" />
                </div>
            </div>


            <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                show={open}
            >
                <div className={open ? 'flex': 'hidden'}>
                    {children}
                </div>
            </Transition>
        </div>
    )
}