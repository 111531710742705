import { useContext, useEffect, useState } from "react";
import { AvailableColors, AvailableColorsByAssets } from "../../constants/ColorList";
import { AppContext } from "../../context/AppContext/AppContextProvider";
import { RenderTypes } from "../../constants/RenderTypes";
import { _3dService } from "../../services/_3DService";
import { getRGB } from "../../utils/color";

export const ColorPallette = ({ }) => {
    const {file, currentColor, setCurrentColor, renderType, changeFileColor, _2dAssetURLList} = useContext(AppContext)

    const [colorList, setColorList] = useState([])

    const handleColorClick = (color, asset) => {
        setCurrentColor(color);
        if(renderType === RenderTypes["2D"]) {
            changeFileColor(color, asset)
        } else if(renderType === RenderTypes["3D"]) {
            _3dService.changeColor(color)
        }
    };

    useEffect(() => {
        if(!file) return
        const fileName = file.name.split('.')[0]
        console.log(fileName)
        let colorList = AvailableColorsByAssets[fileName] || AvailableColors
        setColorList(colorList)
        setCurrentColor(colorList[0])
    }, [file])

    return (
        <div className="grid-container">
            {/* {_2dAssetURLList.map((asset, index) => {
                let color = getRGB(asset.src_color)
                return (
                    <div
                        key={index}
                        className={"grid-item" + (currentColor === color ? " active" : "")}
                        style={{ background: color }}
                        onClick={() => handleColorClick(color, asset)}
                    ></div>
                )
            })} */}

            {
                colorList.map((color, index) => {
                    return (
                        <div
                            key={index}
                            className={"grid-item border-2" + (currentColor === color ? " active" : "")}
                            style={{ background: color }}
                            onClick={() => handleColorClick(color, null)}
                        ></div>
                    )
                })
            }
        </div>
    );
};
