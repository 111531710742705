export const AvailableColors = [
    "#FF0000",
    "#1B4154",
    "#FFB800",
    "#307F00",
    "#73D8AD",
    // "#50C0FF",
    // "#586CD4",
    // "#D4589B",
    // "#8A4242",
    // "#FF8A00",
    // "#8B7D00"
]

export const AvailableColorsByAssets = {
    '7adb35b5-d976-461c-b043-f9790b3c15fa': [
        "#FFFFFF",
        "#FF0000",
        "#1B4154",
        "#FFB800",
        "#307F00",
    ],
    '840a76c0-f752-450f-bc03-4fce9f2612ad': [
        "#FF0000",
        "#ffffff",
        "#363636",
        "#6e6f74",
        "#1B4154",
        "#FFB800",
        "#307F00",
    ]
}