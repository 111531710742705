import './App.css';
import { Viewer } from './components/Viewer/Viewer';
import { Preview } from './components/Preview/Preview';
import { ColorPallette } from './components/ColorPallette/ColorPallette';
import { Section } from './components/Sections/Section';
import { useContext, useState } from 'react';
import { AppContext } from './context/AppContext/AppContextProvider';
import { FileUpload } from './components/FileUpload/FileUpload';
import { RenderTypes } from './constants/RenderTypes';
import { _3dService } from './services/_3DService';
import { Loader } from './components/Loader/loader';
import { ImageSlider } from './components/ImageSlider/ImageSlider';
import { ExportAssetsPopup } from './components/Popups/ExportAssetsPopup';

function App() {
    const { file, setRenderType, loading, download2DImage, _3dAssetURL } = useContext(AppContext)
    const [currentScreen, setCurrentScreen] = useState(1)
    const [showPopup, setShowPopup] = useState(window.screen.width < 800 ? true: false)

    return (
        <div className="App">
            <div className="container flex flex-col md:flex-row">
                <div className="left flex flex-col h-[65%] md:h-full max-h-full">
                    <div className='h-[75%] lg:h-[80%] '>
                        {
                            file ? <>
                                
                                <Viewer></Viewer>
                            </>
                                :
                                <>
                                    
                                </>
                        }

                    </div>
                    <div className="py-[20px] border-t-[3px] border-[#999] h-[160px]">
                        <div className='flex w-full overflow-auto'>
                            <ImageSlider></ImageSlider>
                            <FileUpload></FileUpload>
                        </div>
                    </div>
                </div>
                <div className="right px-[20px]">
                    {/* <div className="firstContent">
                        <Section
                            title={'Create Colored Assets'}
                            onClick={() => {
                                setRenderType(RenderTypes['2D'])
                                setCurrentScreen(1)
                            }}
                            open={currentScreen === 1}
                        >
                            <div className="borderContainer">
                                <div className="colorContent" id="colorContent">
                                    <div className="subtext"> Select the variant color you want to export </div>
                                    <ColorPallette></ColorPallette>
                                </div>
                                <div>
                                    <button 
                                        className='border-2 border-black px-5 py-2 mt-5 cursor-pointer'
                                        onClick={() => {
                                            download2DImage()
                                        }}
                                    >Export Asset</button>
                                </div>
                            </div>
                        </Section>
                    </div> */}
                    {
                        _3dAssetURL !== null && <>
                            {/* <div className="secondContent">
                                <Section
                                    title={'Create 3D Colored Assets'}
                                    onClick={() => {
                                        setRenderType(RenderTypes['3D'])
                                        setCurrentScreen(2)
                                    }}
                                    open={currentScreen === 2}
                                >
                                    <div className="borderContainer">
                                        <div className="colorContent" id="colorContent">
                                            <div className="subtext"> Select the variant color you want to export </div>
                                            <ColorPallette></ColorPallette>
                                        </div>

                                        <div>
                                            <button 
                                                className='border-2 border-black px-5 py-2 mt-5 cursor-pointer'
                                                onClick={() => {
                                                    _3dService.exportGLB()
                                                }}
                                            >Export Asset</button>
                                        </div>
                                    </div>
                                </Section>

                            </div> */}
                            <div className="thirdContent">
                                <Section
                                    title={'Create Asset Shots'}
                                    onClick={() => {
                                        setRenderType(RenderTypes['3D'])
                                        setCurrentScreen(3)
                                    }}
                                    open={true}
                                >
                                    <div className="borderContainer">
                                        <div className="colorContent" id="colorContent">
                                            <div className="subtext"> Select the variant color you want to export </div>
                                            <ColorPallette></ColorPallette>
                                        </div>

                                        <div className='md:flex hidden'>
                                            <button 
                                                className='border-2 border-black px-5 py-2 mt-5 cursor-pointer hover:bg-white'
                                                onClick={() => {
                                                    _3dService.exportGLB()
                                                }}
                                            >
                                                Export 3D Asset
                                            </button>
                                            <button 
                                                className='mx-2 border-2 border-black px-5 py-2 mt-5 cursor-pointer hover:bg-white'
                                                onClick={() => {
                                                    _3dService.start360Capture(() => {
                                                        alert("Video was successfully generated")
                                                    })
                                                }}
                                            >
                                                Export 360 Video Asset
                                            </button>

                                            <button 
                                                className='border-2 border-black px-5 py-2 mt-5 cursor-pointer hover:bg-white'
                                                onClick={() => {
                                                    _3dService.start360PhotosCapture(() => {
                                                        alert("Angle Photos were successfully generated. Please check the folder that you selected earlier.")
                                                    })
                                                }}
                                            >
                                                Export Images At Different Angles
                                            </button>
                                        </div>

                                        {/* <div className='flex md:hidden mb-2'>
                                            <button 
                                                className='w-full border-2 border-black px-5 py-2 mt-5 cursor-pointer'
                                                onClick={() => {
                                                    setShowPopup(true)
                                                }}
                                            >
                                                Export Assets
                                            </button>
                                        </div> */}
                                    </div>
                                </Section>
                            </div>
                        </>
                    }
                    
                </div>
            </div>
            <Loader show={loading}></Loader>
            <ExportAssetsPopup 
                open={showPopup}
                onClose={() => setShowPopup(false)}
            ></ExportAssetsPopup>
        </div>
    );
}

export default App;
