import {AnisotropyPlugin, AssetManagerPlugin, BloomPlugin, FrameFadePlugin, GBufferPlugin, GLTFAnimationPlugin, GammaCorrectionPlugin, GroundPlugin, PickingPlugin, ProgressivePlugin, SSAOPlugin, SSRPlugin, TemporalAAPlugin, TonemapPlugin, ViewerApp, Color, AssetExporterPlugin, downloadBlob, CanvasRecorderPlugin, PopmotionPlugin, DiamondPlugin} from "webgi";
class _3DService {

    constructor() {
        this.canvas = null
        this.viewer = null
        this.assetManager = null
        this.controls = null

        this.viewerInitialized = false
        this.viewerInitStarted = false

        this.assetOptions = {
            autoScale: true, // Scales the object before adding to the scene.
            autoScaleRadius: 2, // Scales the object bounding box to 2 World Units, if autoScale is true
            pseudoCenter: true, // centers the object to origin on load
            // check docs for other options (if required) 
        }

        this.assets = [] // 3d model

        this.setLoading = () => {}
    }

    setLoadingFunction(setLoading) {
        if(setLoading) {
            this.setLoading = setLoading
        }
    }

    async initialize3D(assetURL = '') {
        if(this.viewerInitialized) {
            this.get3dAsset(assetURL)
            return
        }

        if(this.viewerInitStarted) return

        this.setLoading(true)
        this.viewerInitStarted = true
        this.canvas = document.getElementById('canvas')
        const viewer = new ViewerApp({
            canvas: this.canvas,
            useRgbm: true, // Use HDR RGBM Pipeline. false will use HDR Half float pipeline
            useGBufferDepth: true,
        })

        this.viewer = viewer

        const controls = this.viewer.scene.activeCamera.controls;
        controls.autoRotate = true;
        controls.autoRotateSpeed = 5;
        controls.rotateSpeed = 2.0;

        this.controls = controls

        const manager = new AssetManagerPlugin();
        await viewer.addPlugin(manager);

        this.assetManager = manager

        await viewer.setEnvironmentMap("https://dist.pixotronics.com/webgi/assets/hdr/gem_2.hdr.png?v=1");

        this.viewer.scene.envMapIntensity = 2.0
        this.get3dAsset(assetURL)

        await viewer.addPlugin(TonemapPlugin);
        await viewer.addPlugin(PopmotionPlugin);
        await viewer.addPlugin(new ProgressivePlugin(32))
        await viewer.addPlugin(GammaCorrectionPlugin)
        await viewer.addPlugin(DiamondPlugin)
        await viewer.addPlugin(SSRPlugin)
        await viewer.addPlugin(SSAOPlugin)
        await viewer.addPlugin(FrameFadePlugin)
        await viewer.addPlugin(GLTFAnimationPlugin)
        await viewer.addPlugin(GroundPlugin)
        await viewer.addPlugin(BloomPlugin)
        await viewer.addPlugin(TemporalAAPlugin)
        await viewer.addPlugin(AnisotropyPlugin)

        await viewer.addPlugin(AssetExporterPlugin)
        await viewer.addPlugin(CanvasRecorderPlugin)
        

        viewer.renderer.renderScale = 2
        viewer.renderer.refreshPipeline()
        this.viewerInitialized = true

        this.setLoading(false)
    }

    async get3dAsset(assetURL) {
        this.viewer.scene.removeSceneModels()
        this.setLoading(true)
        const manager = this.assetManager
        this.assets = await manager.addFromPath(assetURL, this.assetOptions)
        this.setLoading(false)
    }

    reset3DView() {
        this.controls.reset()
    }

    changeColor(color) {
        this.assets.forEach(asset => {
            asset._modelObject.traverse(child => {
                if(child.name.toLowerCase() === 'body') {
                    console.log(child)
                    if(child.material) {
                        child.material.color.set(color)
                    }
                }
            })
        })

        this.viewer.setDirty()
    }

    async exportGLB() {
        if(!this.viewerInitialized) return
        this.setLoading(true)
        const options = {
            compress: true, // This will work if GLTFDracoExportPlugin is added 
            name: 'scene', 
            viewerConfig: true // This also added the viewer config and plugin settings to the glb
          }
          // Get the blob
          const blob = await this.viewer.getPlugin(AssetExporterPlugin).exportScene(options);
          // Download the blob
          if (blob) downloadBlob(blob, options.name + '.' + blob.ext)
          this.setLoading(false)
    }

    async start360Capture(onComplete = () => {}) {
        this.setLoading(true)
        // Get the plugin instance
        const canvasRecorder = this.viewer.getPlugin(CanvasRecorderPlugin);

        // Configure the plugin as needed, e.g., setting output format or quality
        canvasRecorder.mimeType = 'video/mp4'
        canvasRecorder.videoFrameRate = 30 // Example bitrate

        // Start capturing
        canvasRecorder.recordCamera360(12).then(blob => {
            console.log(blob)
            if (blob) downloadBlob(blob, 'video.mp4')
            onComplete()
            this.setLoading(false)
        }).catch(err => {
            console.log(err)
            this.setLoading(false)
        });
    }

    async start360PhotosCapture(onComplete = () => {}) {
        this.setLoading(true)
        // Get the plugin instance
        const canvasRecorder = this.viewer.getPlugin(CanvasRecorderPlugin);

        // Configure the plugin as needed, e.g., setting output format or quality
        canvasRecorder.mimeType = 'image/jpeg'
        canvasRecorder.videoFrameRate = 30 // Example bitrate

        // Start capturing
        canvasRecorder.recordCamera360(12).then(blob => {
            console.log(blob)
            if (blob) downloadBlob(blob, 'video.mp4')
            onComplete()
            this.setLoading(false)
        }).catch(err => {
            console.log(err)
            this.setLoading(false)
        });

        setTimeout(() => {
            this.setLoading(false)
            canvasRecorder.stopRecording()
        }, 20000)
    }

}

let _3dService = new _3DService()

export {
    _3dService
}